import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProjectCard from './ProjectCard';
import Grid from '@mui/material/Grid';
import { REACT_APP_FUNCTIONS_URL } from '../config';

const Projects = () => {
  const [projects, setProjects] = useState([]);

  const fetchData = async () => {
    try {
      let firebaseProjects = await axios.get(`https://readProjects${REACT_APP_FUNCTIONS_URL}`);
      setProjects(firebaseProjects.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (projects instanceof Promise) {
    return <div>Loading...</div>;
  }

  return (
    <section className='projects' id='projects'>
      <h2>{'>'} Projects</h2>
      <div className='git'>
        Are you looking for the source code? Check out my{' '}
        <a href='https://github.com/silmu' target='_blank' rel='noreferrer'>
          GitHub
        </a>
      </div>
      <div>
        <Grid container spacing={2}>
          {Array.isArray(projects) &&
            projects.map(project => {
              return (
                <Grid item xs={12} sm={6} md={4} key={project.id}>
                  <ProjectCard {...project} />
                </Grid>
              );
            })}
        </Grid>
      </div>
    </section>
  );
};
export default Projects;
