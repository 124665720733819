import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { REACT_APP_FIREBASE_BUCKET } from '../config';

const ProjectCard = ({ name, technologies, description, url, img, source_code }) => {
  img = `${REACT_APP_FIREBASE_BUCKET}/projects%2F${img}?alt=media`;
  return (
    <Card sx={{ width: 325 }}>
      <CardActionArea>
        <a href={url} target='_blank' rel='noreferrer'>
          <CardMedia component='img' height='230' image={img} alt={name} />
        </a>
      </CardActionArea>
      <CardContent sx={{ height: 150 }}>
        <Typography gutterBottom variant='h5' component='div'>
          {name}
        </Typography>
        <Typography variant='subtitle2'>{technologies}</Typography>
        <Typography variant='body2' color='text.secondary'>
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size='small'>
          <a href={url} target='_blank' rel='noreferrer'>
            View
          </a>
        </Button>
        <Button size='small'>
          <a href={source_code} target='_blank' rel='noreferrer'>
            Code
          </a>
        </Button>
      </CardActions>
    </Card>
  );
};
export default ProjectCard;
