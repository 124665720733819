import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Skills = () => {
  const skills = {
    'Front End': ['React', 'Redux Toolkit', 'TypeScript', 'Libraries', ['Material UI', 'Bootstrap'], 'Figma', 'Font Awesome'],
    'Back End': [
      'Node.js, Express.js',
      'Rate limiting',
      'JWT authorization and authentication',
      'User role management',
      'Cron jobs for task automation',
      'Redis caching, in-memory caching',
      'Nginx',
      'Integration with services:',
      ['Meta ConversionAPI', 'Google OAuth', 'GoogleAds', 'AWS'],
      'Nodemailer, MailerLite for subscription services',
      'Logging with Morgan, Winston',
      'Testing with Jest, Supertest',
    ],
    Databases: [
      'Relational:',
      ['Postgres', 'MySQL'],
      'Non-relational:',
      ['DynamoDB', 'MongoDB'],
      'ORMs:',
      ['Objection ORM', 'Prisma ORM', 'raw SQL queries with “pg” library'],
      'knex',
      'Complex SQL queries with subqueries',
      'SQL transactions',
      'Migrations',
      'Procedures',
    ],
    'System design': [
      'Led a development team and a data team to develop a classification system for a large-scale project',
      'Database design and optimization',
      'Query optimization for large datasets',
      'Hierarchical data storage and retrieval',
      'Caching strategies',
      'Message queues',
      'RESTful API design',
    ],
    'CI/CD': ['AWS CodePipeline', 'AWS CodeCommit', 'AWS CodeDeploy', 'GitHub Actions'],
    'Concepts and technologies': [
      'Containers',
      ['Docker', 'Automation of a Dockerized application with Makefile scripting'],
      'AWS',
      ['Deployment of a Dockerized application to EC2 instance', 'AWS SDK', 'AWS S3', 'DynamoDB', 'RDS', 'Elastic Load Balancer (ELB)'],
      'Algorithms:',
      ['Nested Set Model', 'Recursion'],
      'Git Version Control',
      'Asynchronous programming',
      'Object Oriented Programming',
      'Functional Programming',
    ],
  };
  const skillCategories = Object.keys(skills);
  return (
    <section id='skills'>
      <details open>
        <summary>
          <h3>{'>'}Skills</h3>
        </summary>
        {/* <Grid container spacing={2}>
          <div className='skills'>
            {skillCategories.map(category => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={category}>
                <Paper sx={{ p: 4, height: '100%' }}>
                  <h4>{category}</h4>
                  <ul>
                    {skills[category].map(skill => {
                      if (Array.isArray(skill)) {
                        return (
                          <ul key={skill}>
                            {skill.map(subskill => (
                              <li key={subskill}>{subskill}</li>
                            ))}
                          </ul>
                        );
                      } else {
                        return <li key={skill}>{skill}</li>;
                      }
                    })}
                  </ul>
                </Paper>
              </Grid>
            ))}
          </div>
        </Grid> */}
        <Grid container spacing={2}>
          <div className='skills'>
            {skillCategories.map(category => (
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2} key={category}>
                <Paper sx={{ p: 4, height: '100%' }}>
                  <h4>{category}</h4>
                  <ul>
                    {skills[category].map(skill => {
                      if (Array.isArray(skill)) {
                        return (
                          <ul key={skill}>
                            {skill.map(subskill => (
                              <li key={subskill}>{subskill}</li>
                            ))}
                          </ul>
                        );
                      } else {
                        return <li key={skill}>{skill}</li>;
                      }
                    })}
                  </ul>
                </Paper>
              </Grid>
            ))}
          </div>
        </Grid>
      </details>
    </section>
  );
};
export default Skills;
