import * as React from 'react';
import { REACT_APP_FIREBASE_BUCKET } from '../config';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

const pages = [
  { name: 'Projects', link: '#projects' },
  { name: 'About', link: '#about' },
  { name: 'Skills', link: '#skills' },
  { name: 'Contacts', link: '#contacts' },
];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position='sticky'
      sx={{
        backgroundColor: 'black',
      }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          {/***************** Mobile Menu *******************/}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(page => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign='center'>
                    <a href={page.link} style={{ color: 'black', textDecoration: 'none' }}>
                      {page.name}
                    </a>
                  </Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign='center'>
                  <a
                    href='https://github.com/silmu'
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    GitHub
                  </a>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign='center'>
                  <a
                    href='/assets/Web_Developer_Svetlana_Raitina_CV.pdf'
                    download='R_Svetlana_Web_Developer_CV'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    CV
                  </a>
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <a href='#' className='logo'>
              Svetlana Raitina
            </a>
          </Typography>
          {/***************** Menu *******************/}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'start',
            }}
          >
            {pages.map(page => (
              <Button
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                  textTransform: 'capitalize',
                  fontFamily: 'JetBrains Mono',
                }}
              >
                <a href={page.link}>{page.name}</a>
              </Button>
            ))}
            <Button
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: 'white',
                display: 'block',
                textTransform: 'capitalize',
                fontFamily: 'JetBrains Mono',
              }}
            >
              <a href='https://github.com/silmu' target='_blank' rel='noreferrer'>
                GitHub
              </a>
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: 'white',
                display: 'block',
                textTransform: 'capitalize',
                fontFamily: 'JetBrains Mono',
              }}
            >
              <a
                href={`${REACT_APP_FIREBASE_BUCKET}/Fullstack_Web_Developer_Raitina_CV.docx.pdf?alt=media`}
                download='Svetlana_Raitina_Web_Developer_CV'
                target='_blank'
                rel='noreferrer'
              >
                CV
              </a>
            </Button>
          </Box>
          <Typography
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignSelf: 'center',
            }}
          >
            {/*eslint-disable-next-line*/}
            <a href='#' className='logo'>
              Svetlana Raitina
            </a>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
