import React from 'react';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import IconButton from '@mui/material/IconButton';

const ArrowUp = () => {
  return (
    <IconButton onClick={() => window.scroll(0, 0)}>
      <ArrowCircleUpIcon
        sx={{
          color: '#00fee3',
          position: 'fixed',
          bottom: '30vh',
          right: '1rem',
          fontSize: '3.5rem',
        }}
      />
    </IconButton>
  );
};

export default ArrowUp;
