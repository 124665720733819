import React from 'react';
import Header from './../components/Header';
import Banner from '../components/Banner';
import Main from '../components/Main';
import ArrowUp from '../components/UI_elements/ArrowUp';

const Layout = () => {
  return (
    <div>
      <Header />
      <Banner />
      <Main />
      <ArrowUp />
    </div>
  );
};

export default Layout;
