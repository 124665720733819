import React, { useState } from 'react';
import { REACT_APP_FIREBASE_BUCKET } from '../config';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';

const LicenseCard = ({ name, img, url }) => {
  const [previewOn, setPreviewOn] = useState(false);
  img = `${REACT_APP_FIREBASE_BUCKET}/licenses%2F${img}?alt=media`;
  const handlePreview = () => {
    setPreviewOn(!previewOn);
  };
  return (
    <Card sx={{ width: 325 }} className={previewOn ? 'cardPreview' : ''}>
      <CardActionArea onClick={handlePreview}>
        <CardMedia
          component='img'
          height='260'
          image={img}
          alt={name}
          loading='lazy'
          className={previewOn ? 'previewOn' : ''}
          sx={{
            borderBottom: '1px solid lightgray',
            filter: 'grayscale(100%)',
          }}
        />
      </CardActionArea>
      <CardContent>
        <Typography gutterBottom variant='h6' component='div' height='4rem'>
          {name}
        </Typography>
      </CardContent>

      <CardActions>
        <Button onClick={handlePreview} size='small' sx={{ color: 'black', fontWeight: 'bold' }}>
          Preview
        </Button>
        <Button size='small'>
          <a href={url} target='_blank' rel='noreferrer'>
            Verify
          </a>
        </Button>
      </CardActions>
    </Card>
  );
};
export default LicenseCard;
