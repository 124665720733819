import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { REACT_APP_FUNCTIONS_URL } from '../config';
import ProjectCard from './ProjectCard';
import Grid from '@mui/material/Grid';

const ProjectsInDevelopment = () => {
  const [projects, setProjects] = useState([]);

  const fetchData = async () => {
    try {
      let firebaseProjects = await axios.get(`https://readProjectsInDev${REACT_APP_FUNCTIONS_URL}`);
      setProjects(firebaseProjects.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (projects instanceof Promise) {
    return <div>Loading...</div>;
  }

  return (
    <section className='projects' id='projects-in-development'>
      <h2>{'>'} Projects in development</h2>
      <h3>Take a sneak peak at the latest projects in development</h3>
      <div>
        <Grid container spacing={2}>
          {Array.isArray(projects) &&
            projects.map(project => {
              return (
                <Grid item xs={12} sm={6} md={4} key={project.id}>
                  <ProjectCard {...project} />
                </Grid>
              );
            })}
        </Grid>
      </div>
    </section>
  );
};
export default ProjectsInDevelopment;
