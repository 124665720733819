import React, { useEffect } from 'react';

const HeaderSquares = ({ colors, getRandomNum }) => {
  const squaresNum = 1770;

  const fadeSquaresInOut = randomSquares => {
    // Fade in all squares at once
    randomSquares.forEach(index => {
      const square = document.querySelector(`.square:nth-child(${index + 1})`);
      if (square) {
        const newColor = colors[getRandomNum()];
        square.style.transition = '2s'; // Adjust the duration of the fade in animation
        square.style.backgroundColor = newColor;
        square.style.boxShadow = `0 0 2px ${newColor}, 0 0 10px ${newColor}`;
        square.style.opacity = '1';
      }
    });

    // After fade in, fade out squares one by one
    randomSquares.forEach((index, i) => {
      const square = document.querySelector(`.square:nth-child(${index + 1})`);
      if (square) {
        setTimeout(() => {
          square.style.transition = '2s'; // Adjust the duration of the fade out animation
          square.style.opacity = '0';
          setTimeout(() => {
            square.style.backgroundColor = '#1d1d1d';
            square.style.boxShadow = `0 0 2px #000`;
            square.style.opacity = '1';
          }, 2000); // Adjust the duration of the delay before changing back to initial state
        }, i * 100); // Adjust the duration of the delay between each square
      }
    });

    // After fading out, initiate the next cycle
    const newRandomSquares = [];
    const numRandomSquares = Math.floor(squaresNum * 0.01); // Adjust 0.1 to change the percentage of squares that will change color on load
    while (newRandomSquares.length < numRandomSquares) {
      const randomIndex = Math.floor(Math.random() * squaresNum);
      if (!newRandomSquares.includes(randomIndex)) {
        newRandomSquares.push(randomIndex);
      }
    }
    setTimeout(() => fadeSquaresInOut(newRandomSquares), 1000); // Adjust the duration of the delay before fading in the squares
  };

  useEffect(() => {
    // Start the animation loop when the component mounts
    const initialRandomSquares = [];
    const numInitialRandomSquares = Math.floor(squaresNum * 0.01); // Adjust 0.1 to change the percentage of squares that will change color initially
    while (initialRandomSquares.length < numInitialRandomSquares) {
      const randomIndex = Math.floor(Math.random() * squaresNum);
      if (!initialRandomSquares.includes(randomIndex)) {
        initialRandomSquares.push(randomIndex);
      }
    }
    fadeSquaresInOut(initialRandomSquares);
  }, []);

  const handleMouseOver = e => {
    const newColor = colors[getRandomNum()];
    e.currentTarget.style.backgroundColor = newColor;
    e.currentTarget.style.boxShadow = `0 0 2px ${newColor}, 0 0 10px ${newColor}`;
  };

  const handleMouseLeave = e => {
    e.currentTarget.style.backgroundColor = '#1d1d1d';
    e.currentTarget.style.boxShadow = `0 0 2px #000`;
  };

  const squares = [];
  for (let i = 0; i < squaresNum; i++) {
    squares.push(<div className='square' key={i} onMouseOver={e => handleMouseOver(e)} onMouseLeave={e => handleMouseLeave(e)}></div>);
  }
  return squares;
};

export default HeaderSquares;
