import React from 'react';
import { REACT_APP_FIREBASE_BUCKET } from '../config';
import Button from './UI_elements/Button';
import Skills from './Skills';
import Licenses from './Licenses';

const AboutMe = () => {
  const img = `${REACT_APP_FIREBASE_BUCKET}/profile_pic.png?alt=media`;
  return (
    <>
      <section id='about'>
        <details open>
          <summary>
            <h2>{'>'} About me</h2>
          </summary>
          <div className='whoIAm'>
            <div className='leftSide'>
              <div className='profilePic'>
                <img src={img} alt='Profile' />
              </div>
            </div>
            <div className='rightSide'>
              <p>
                Hi, I'm Sveta, a full-stack web developer at{' '}
                <a href='https://geeklab.app' target='_blank' rel='noreferrer'>
                  GeekLab
                </a>{' '}
                with a passion for building scalable and efficient applications.
                <br />I specialize in building web applications using <b>React</b> and <b>Node.js</b>, with experience in using <b>Postgres</b> and{' '}
                <b>MySQL</b> databases. My experience includes developing robust backend systems, implementing JWT authentication, authorization and
                building RESTful APIs.
                <br />
                When I'm not working on projects, you can find me sharpening my coding skills by taking on coding challenges on Codewars. I'm a
                competitive coder, and I love finding ways to make my code more concise and efficient. I also enjoy building my own applications using
                the latest tools and techniques.
                <br />
                I'm excited to collaborate with like-minded individuals and create innovative solutions!
              </p>
              <div className='btn-container'>
                <a
                  href={`${REACT_APP_FIREBASE_BUCKET}/Fullstack_Web_Developer_Raitina_CV.docx.pdf?alt=media`}
                  download='Svetlana_Raitina_Fullstack_Developer_CV'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Button text={'Download CV'}></Button>
                </a>
              </div>
            </div>
          </div>
        </details>
      </section>
      <Skills />
      <Licenses />
    </>
  );
};

export default AboutMe;
