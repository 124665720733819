import React from "react";
import { useState } from "react";
import Squares from "./UI_elements/HeaderSquares";

const Banner = () => {
  const [headerColor, setHeadercolor] = useState("");
  const colors = ["#e300fe", "#00fe65", "#fe019a", "#00fee3"];

  const getRandomNum = () => Math.floor(Math.random() * colors.length);

  const changeHeaderColor = () => {
    const newColor = colors[getRandomNum()];
    setHeadercolor(newColor);
  };

  return (
    <div className='container'>
      <h1 style={{ color: headerColor }} onClick={changeHeaderColor}>
        Hi! <span className='hand-wave'>👋</span> I am Sveta, the web developer
      </h1>

      <Squares colors={colors} getRandomNum={getRandomNum} />
    </div>
  );
};

export default Banner;
