import React from "react";
import Projects from "./Projects";
import AboutMe from "./AboutMe";
import Footer from "./Footer";
import ProjectsInDevelopment from "./ProjectsInDevelopment";

const Home = () => {
  return (
    <>
      <Projects />
      <ProjectsInDevelopment />
      <AboutMe />
      <Footer />
    </>
  );
};

export default Home;
