import React from 'react';
import styles from './Button.modules.css';

const Button = ({ text, click }) => {
  return (
    <>
      <div className='wrapper'>
        <button className='btn' onClick={click}>
          {text}
        </button>
      </div>
      {/* Filter: https://css-tricks.com/gooey-effect/ */}
      <svg
        style={{ visibility: 'hidden', position: 'absolute' }}
        width='0'
        height='0'
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
      >
        <defs>
          <filter id='goo'>
            <feGaussianBlur
              in='SourceGraphic'
              stdDeviation='10'
              result='blur'
            />
            <feColorMatrix
              in='blur'
              mode='matrix'
              values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9'
              result='goo'
            />
            <feComposite in='SourceGraphic' in2='goo' operator='atop' />
          </filter>
        </defs>
      </svg>
    </>
  );
};
export default Button;
