import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { REACT_APP_FUNCTIONS_URL } from '../config';
import Grid from '@mui/material/Grid';
import LicenseCard from './LicenseCard';

const Licenses = () => {
  const [licenses, setLicenses] = useState([]);

  const fetchData = async () => {
    try {
      let firebaseProjects = await axios.get(`https://readLicenses${REACT_APP_FUNCTIONS_URL}`);
      // Order licences by order_index
      firebaseProjects.data.sort((a, b) => a.order_index - b.order_index);
      setLicenses(firebaseProjects.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (licenses instanceof Promise) {
    return <div>Loading...</div>;
  }

  return (
    <section>
      <details open>
        <summary>
          <h3>{'>'} Licenses & certifications</h3>
        </summary>
        <div>
          <Grid container spacing={2}>
            {Array.isArray(licenses) &&
              licenses.map(license => {
                return (
                  <Grid item xs={12} sm={6} md={4} l={3} key={license.name}>
                    <LicenseCard {...license} />
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </details>
    </section>
  );
};
export default Licenses;
