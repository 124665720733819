import React, { useState } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import Button from "./UI_elements/Button";
import emailjs from "@emailjs/browser";

const Footer = () => {
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const displayMessage = msg => {
    setMessage(msg);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };

  const handleSubmit = e => {
    e.preventDefault();
    let SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
    let TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
    let USER_ID = process.env.REACT_APP_USER_ID;

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      result => {
        setMessageSent(true);
        displayMessage("Message was sent. Thank you!");
      },
      error => {
        console.log(error.text);
        setMessageSent(false);
        displayMessage("Error occured. Please, use LinkedIn to send me a message.");
      }
    );
  };
  return (
    <footer className='contacts'>
      <h2>{">"} Contact me</h2>
      <div id='contacts'>
        <a href='https://www.linkedin.com/in/svetlana-raitina/' target='_blank' rel='noreferrer'>
          <LinkedInIcon sx={{ color: "#00fee3", fontSize: 40 }} />
        </a>
        <a href='mailto:kawaiihotaru@protonmail.com' target='_blank' rel='noreferrer'>
          <EmailIcon sx={{ color: "#00fee3", fontSize: 40 }} />
        </a>
        <a href='https://github.com/silmu' target='_blank' rel='noreferrer'>
          <GitHubIcon sx={{ color: "#00fee3", fontSize: 40 }} />
        </a>
      </div>
      {showMessage ? <div className={`mailStatus ${messageSent ? "Success" : "Fail"}`}>{message}</div> : ""}
      <form onSubmit={handleSubmit}>
        <fieldset>
          <label htmlFor='name'>Your name</label>
          <input type='text' id='name' name='name' required />
        </fieldset>
        <fieldset>
          <label htmlFor='email'>Your email</label>
          <input type='email' id='email' name='email' required />
        </fieldset>
        <fieldset>
          <label htmlFor='message'>Your message</label>
          <textarea name='message' id='message' required></textarea>
        </fieldset>
        <Button type='submit' text='SEND' />
      </form>
    </footer>
  );
};
export default Footer;
